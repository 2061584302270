import React from 'react';
import { PRODUCTS } from '../../helpers/constants';

const ServicePurchaseSection = ({
  destination = 'Aruba',
  websiteUrl,
  company,
  productSku = PRODUCTS.doctorNotes,
}) => (
  <div className='purchase-container-block'>
    <h4 className='ad-header-center'>One of the medicines you are taking with you is
      either controlled or prohibited in <span className='purchase-destination-color'>{destination}</span>
    </h4>
    <p>
      It may be possible to carry your medicines with you, with the right supporting documentation.
      We offer a service to prepare your supporting documentation for you to carry all the needed medicines with you for your trip.
      <br />
      <br />
			This service includes:
    </p>
    <ul>
      <li>Validate quantities/strength you are allowed to carry</li>
      <li>Cover letter with medical regulations at your destination</li>
      <li>Prepare doctor's travel medical letter</li>
    </ul>
    <br />
    <a
      target='_blank'
      href={`${websiteUrl}/shop/product/${productSku}?delivery=0&quantity=1&source=${company}`}
      rel="noopener noreferrer"
    >
      <button className='btn-lgt' id="purchase-button">
        Purchase service
      </button>
    </a>
  </div>
);

export default ServicePurchaseSection;
