import React, { Component } from 'react';
import { Box, Grid, Modal } from '@material-ui/core';
import { Link } from 'gatsby';
import LocationAndDestination from '../components/LocationAndDestination';
import DrugTable from '../components/DrugTable';
import DrugTableMobile from '../components/DrugTableMobile';
import CountryAndDrugList from '../components/CountryAndDrugList';
import RestrictionTable from '../components/RestrictionTable';
import NCAComponent from '../components/NCAComponent';
import TopTips from '../components/TopTips';
import Layout from '../components/Layout';
import allSvc from '../services/allServices';
import Documents from '../components/Documents';
import NoInfo from '../components/NoInfo';
import ReportErrorModal from '../components/ErrorModal/ReportErrorModal';
import DocModal from '../components/DocModal/DocModal';
import reportHelpers from '../helpers/reportHelpers';
import UnmatchedDrugsList from '../components/UnmatchedDrugsList';
import LoadingSpinner from '../components/LoadingSpinner/LoadingSpinner';
import TravelVaccinesAd from '../components/Adverts/TravelVaccinesAd';
import ServicePurchaseSection from '../components/Adverts/ServicePurchaseSection';
import useConfig from '../helpers/hooks/useConfig';
import { PRODUCTS } from '../helpers/constants';

/* RESTRICTION (temporary) for all users to see only short view of form */
const SHORT_FORM_RESTRICTION = false;

// Props required from previous page
/**
 * reportId
 * destinationId - otherwise known as name in this case
 */
const styles = {
  margin: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  sidebarContainer: {
    marginTop: '30px',
  },
  disclaimer: {
    marginTop: '5px',
    fontSize: '10px',
    color: 'var(--doc-green)',
    border: '0px',
    backgroundColor: 'transparent',
    padding: '0px',
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: 'var(--doc-pink) !important',
    },
  },
};
export default class Report extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDocHq: false,
      isLoading: true,
      company: '',
      productSku: PRODUCTS.doctorNotes,
      startedLoading: new Date(),
      isDisclaimerVisible: false,
      maxWidth: 1900,
    };

    // Function bindings
    this.getReport = getReport.bind(this);
    this.initialiseStateData = initialiseStateData.bind(this);
    this.handleError = handleError.bind(this);
    this.handleResize = handleResize.bind(this);
    async function getReport(jsonAPI, reportId, company) {
      // Axios get using reportId
      return await allSvc
        .getReport(jsonAPI, reportId, company)
        .then(resp => {
          if (resp.success && resp.data) {
            return resp.data;
          } else {
            return { error: 'Error fetching report' };
          }
        })
        .catch(err => ({ error: 'Error fetching report' }));
    }

    function initialiseStateData(data) {
      const dataForState = {};
      /* Environment */
      dataForState.environment = process.env.GATSBY_ENVIRONMENT;
      // Matched drugs
      if (data.matched_drugs) {
        dataForState.matched_drugs = data.matched_drugs;
        dataForState.hasRestrictions = reportHelpers.hasRestrictions(dataForState.matched_drugs);
      }
      // Unmatched drugs
      if (data.unmatched_drugs) dataForState.unmatched_drugs = data.unmatched_drugs;
      // Country Names
      if (data.company) {
        dataForState.company = String(data.company).toLowerCase();
        dataForState.isDocHq = dataForState.company && dataForState.company === 'dochq';
      }
      if (data.from_country && data.from_country.name)
        dataForState.locationName = data.from_country.name;
      if (data.to_country && data.to_country.name)
        dataForState.destinationName = data.to_country.name;
      // NCA data
      if (data.to_country && data.to_country.nca_address)
        dataForState.nca_address = data.to_country.nca_address;
      if (data.to_country && data.to_country.recommendations)
        dataForState.recommendations = data.to_country.recommendations;
      if (data.to_country && data.to_country.documents)
        dataForState.documents = data.to_country.documents;
      if (data.to_country && data.to_country.restrictions)
        dataForState.restrictions = data.to_country.restrictions;
      if (
        data.to_country &&
				data.to_country.vaccine_information &&
				data.to_country.vaccine_information.length !== 0
      )
        dataForState.vaccinesRequired = true;
      dataForState.isLoading = false;
      const renderTime = this.state.startedLoading.getTime() + 500;

      //email passed in url
      if (data.urlEmail) dataForState.urlEmail = data.urlEmail;

      //email passed in url
      if (data.urlEmail) dataForState.urlEmail = data.urlEmail;

      if (renderTime > new Date().getTime()) {
        setTimeout(() => {
          this.setState(dataForState);
        }, renderTime - new Date().getTime());
      } else {
        this.setState(dataForState);
      }
    }
    function handleError() {
      this.setState({ reportError: true, isLoading: false });
    }
    function handleResize() {
      if (typeof window !== 'undefined') {
        window.onresize = this.setState({ maxWidth: window.innerWidth });
      }
    }
  }

  // Before component mounts, check what data is present
  componentWillMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', this.handleResize);
    }
    let dataForState;
	  const { config } = useConfig();
    this.setState({ config });
    // Passed through by navigator
    if (reportHelpers.hasMinimumData(this.props)) {
      // Has minimum data
      this.getReport(config?.activeGateway, this.props.reportId)
        .then(data => {
          if (reportHelpers.reportDataIsValidEnough(data)) {
            dataForState = data;
            this.initialiseStateData(dataForState);
          } else {
            this.handleError();
          }
        }) // handle error
        .catch(err => this.handleError());
    } else {
      // Navigated via email or has no data passed from router
      const isClient = typeof window !== 'undefined';
      if (isClient) {
        const urlParams = typeof window === 'undefined' ? null : reportHelpers.getUrlParams(window.location.href);
        const productSku = String(urlParams?.productSku || PRODUCTS.doctorNotes);
        const company = String(urlParams?.company || 'dochq').toLowerCase();
        this.setState({ isDocHq: true, company, productSku });
        if (urlParams && typeof urlParams.error === 'undefined') {
          // Has URL Params
          if (reportHelpers.hasMinimumData(urlParams)) {
            // Get all data remaining
            this.getReport(config?.activeGateway, urlParams.reportId, urlParams.company)
              .then(data => {
                if (reportHelpers.reportDataIsValidEnough(data)) {
                  dataForState = data;
                  this.initialiseStateData(dataForState);
                } else {
                  // Data is invalid
                  this.handleError();
                }
              }) // handle error
              .catch(err => this.handleError());
          } else {
            // We've got something in the URL params but it isn't enough to do what we need and nothing from the navigator, we are in trouble.
            this.handleError();
          }
        } else {
          // We've got nothing in the URL params and nothing from the navigator, we are in trouble.
          this.handleError();
        }
      }
    }
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.handleResize);
    }
  }
  render() {
    if (
      typeof window !== 'undefined' &&
			this.state.maxWidth === 1900 &&
			window.innerWidth !== this.state.maxWidth
    ) {
      this.setState({ maxWidth: window.innerWidth });
    }
    return (
      <Layout>
        {this.state.isLoading ? (
          <Modal open style={{ backgroundColor: 'var(--doc-light-grey)' }}>
            <div
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                backgroundColor: '#ffffff',
                padding: '20px',
                borderRadius: '5px',
                minWidth: '250px',
                minHeight: '200px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <LoadingSpinner />
            </div>
          </Modal>
        ) : (
          <React.Fragment>
            {this.state.reportError && <ReportErrorModal reportId={this.state.reportId} />}
            <Grid container direction='column' justify='center' alignItems='stretch'>
              <Grid container direction='row' justify='flex-start' alignItems='flex-start'>
                <Grid item xs={12} sm={8}>
                  {/* <ReportHeader email={this.state.email} /> */}
                  <Box pl={2} display="flex" justify="space-between" alignItems="center">
                    <LocationAndDestination
                      locationName={this.state.locationName}
                      destinationName={this.state.destinationName}
                    />
                  </Box>
                  <React.Fragment>
                    {((typeof this.state.matched_drugs !== 'undefined' && this.state.matched_drugs.length > 0) ||
											(typeof this.state.unmatched_drugs !== 'undefined' &&
												this.state.unmatched_drugs.length > 0)) &&
											(this.state.maxWidth > 600 ? (
											  <DrugTable
											    isDocHq={this.state.isDocHq}
											    drugs={this.state.matched_drugs}
											    unmatchedDrugs={this.state.unmatched_drugs}
											  />
											) : (
											  <DrugTableMobile
											    isDocHq={this.state.isDocHq}
											    drugs={this.state.matched_drugs}
											    unmatchedDrugs={this.state.unmatched_drugs}
											  />
											))}
                    {this.state.isDocHq && SHORT_FORM_RESTRICTION && (
                      <>
                        {typeof this.state.unmatched_drugs !== 'undefined' &&
													this.state.unmatched_drugs.length > 0 && (
                          <UnmatchedDrugsList drugs={this.state.unmatched_drugs} />
                        )}
                        {typeof this.state.restrictions !== 'undefined' &&
													(this.state.restrictions.narcotic || this.state.restrictions.psycotropic) &&
													this.state.documents &&
													this.state.hasRestrictions &&
													typeof this.state.documents.length !== 'undefined' &&
													this.state.documents.length > 0 && (
                          <RestrictionTable restrictions={this.state.restrictions} />
                        )}
                        {typeof this.state.matched_drugs !== 'undefined' && this.state.matched_drugs.length > 0 && (
                          <CountryAndDrugList
                            drugs={this.state.matched_drugs}
                            destinationName={this.state.destinationName}
                            documents={this.state.documents}
                          />
                        )}
                        {this.state.documents &&
													typeof this.state.documents.length !== 'undefined' &&
													this.state.documents.length > 0 && (
                          <button
                            className='disclaimer'
                            style={styles.disclaimer}
                            onClick={() => {
                              this.setState(prevState => ({
                                isDisclaimerVisible: !prevState.isDisclaimerVisible,
                              }));
                            }}
                          >
                            {' '}
															*IMPORTANT INFORMATION*
                          </button>
                        )}

                        {this.state.documents &&
													this.state.hasRestrictions &&
													typeof this.state.documents.length !== 'undefined' &&
													this.state.documents.length > 0 && (
                          <div>
                            <p style={{ fontWeight: 'bold' }}>
                              Follow the steps below to travel safely and legally with your medicine:
                            </p>
                            <Documents
                              documents={this.state.documents}
                              destinationName={this.state.destinationName}
                              nca_address={this.state.nca_address}
                            />
                          </div>
                        )}
                        {(typeof this.state.documents === 'undefined' ||
													this.state.documents.length === 0) && (
                          <NoInfo destinationName={this.state.destinationName} />
                        )}
                        <div style={styles.margin}>
                          {this.state.nca_address && (
                            <NCAComponent nca_address={this.state.nca_address} title={true} />
                          )}
                        </div>
                        {this.state.recommendations && <TopTips tips={this.state.recommendations} />}
                      </>
                    )}
                    {this.state.hasRestrictions && (
                      <Box
                        display='flex'
                        alignItems='center'
                        justifyContent='center'
                        pt={4}
                      >
                        <ServicePurchaseSection
                          productSku={this.state.productSku}
						              websiteUrl={this.state.config.websiteUrl}
                          destination={this.state.destinationName}
                          company={this.state.company}
                        />
                      </Box>
                    )}
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'
                      pt={4}
                    >
                      <Link to={`/${this.state.company ? `?company=${this.state.company}` : ''}${this.state.productSku ? `&productSku=${this.state.productSku}` : ''}`}>
                        <button className='btn-swu-green'>
							            New search
                        </button>
                      </Link>
                    </Box>
                  </React.Fragment>
                </Grid>
                {this.state.isDocHq && SHORT_FORM_RESTRICTION && (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={4}
                    direction='column'
                    alignItems='center'
                    justify='center'
                    style={styles.sidebarContainer}
                  >
                    <TravelVaccinesAd />
                    {/* <MalariaMedicationAd /> product isn't currently ready for advertisement*/}
                  </Grid>
                )}
              </Grid>
              <Grid container direction='row' justify='center' alignItems='flex-start'>
                <Grid
                  container
                  item
                  xs={12}
                  sm={4}
                  direction='column'
                  alignItems='center'
                  justify='center'
                  style={styles.sidebarContainer}
                >
                  {/* // Travel insurance advertisement isn't ready yet
              <TravelInsuranceAd /> */}
                </Grid>
              </Grid>
              <DocModal
                isVisible={this.state.isDisclaimerVisible}
                onClose={() => this.setState({ isDisclaimerVisible: false })}
                title='IMPORTANT INFORMATION'
                content={<Disclaimer />}
              />
            </Grid>
          </React.Fragment>
        )}
      </Layout>
    );
  }
}
const Disclaimer = () => (
  <React.Fragment>
    <div>
      <p style={{ marginTop: '0px' }}>
				This database is only intended as a guide for travellers wishing to research information on
				the importation of medicines for personal use across international borders.
      </p>
      <p>
				It is <span style={{ fontWeight: 'bold' }}>not</span> a definitive statement on individual
				country requirements which may change between updates of this database. In addition, the
				implementation and enforcement of the country regulations may deviate from the stated
				information.{' '}
      </p>
      <p>
        <span style={{ fontWeight: 'bold' }}>
					DocHQ cannot accept responsibility for any inaccuracies arising.
        </span>
      </p>
      <p>
				Do always contact the National Competent Authority at least two months ahead of your trip to
				make sure you have all the required documents ready on time for your trip.
      </p>
    </div>
  </React.Fragment>
);
